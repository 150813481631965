/** @jsx jsx */
import PropTypes from 'prop-types';
import { jsx } from '@emotion/core'; // eslint-disable-line

import mouseSVG from '../../images/arrow.svg';

const styles = {
  mouse: {
    position: 'absolute',
    zIndex: 0,
    top: 0,
    left: 0,
    transform: 'rotate(-31deg) scale(0.7)',
    transformOrigin: '-4px 12px',
    // transistion: 'all 0.25s ease-in-out',
  },
};

export const Mouse = ({ position }) => (
  <img
    css={styles.mouse}
    style={{
      top: `${position.y}px`,
      left: `${position.x}px`,
    }}
    src={mouseSVG}
    alt="Mouse"
  />
);

Mouse.propTypes = {
  position: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
  }),
};

Mouse.defaultProps = {
  position: { x: 0, y: 0 },
};

export default Mouse;
