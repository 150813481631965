import React, { Component } from 'react';  // eslint-disable-line
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';

import './index.css';
import Home from './components/Pages/Home';
import { Websockets } from './components/Pages/Experiments';
import MouseLayer from './components/MouseLayer/MouseLayer';

const App = () => (
  <div className="App">
    <Router>
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route path="/experiments/websockets">
          <MouseLayer>
            <Websockets />
          </MouseLayer>
        </Route>
      </Switch>
    </Router>
  </div>
);


export default App;
