export const UNMODIFIED_CHARS = ', .:/\\’\'"`~^!@#$%&*()_=+[]<>?{}|;';
export const UNMODIFIED_CHARS_EXTRA = '&nbsp';
export const POSSIBLE_LETTERS = '1234';
export const LOCAL_SERVER_ADDRESS = '/';

// API Endpints
const endpointer = (endpoint) => `${LOCAL_SERVER_ADDRESS}${endpoint}`;
export const SOCKET_CONNECT = endpointer('socket');
export const SOCKET_DISCONNECT = endpointer('disconnect');
export const SOCKET_POLL = 'poll';
export const MOUSE_MOVE_EVENT = 'mousemove';
export const POSITION_INCOMING = 'position-updated';
