import PropTypes from 'prop-types';
import React from 'react';
import Letter from '../Letter';
import * as constants from '../../constants';

const Segment = ({ children, ...rest }) => (
  <span className="segment">
    {
      children.split('').map((char, index) => (
        // eslint-disable-next-line react/no-array-index-key
        constants.UNMODIFIED_CHARS.split('').includes(char) ? char : <Letter key={`${index}.${char}`} finalLetter={char} {...rest} />
      ))
    }
  </span>
);

Segment.propTypes = {
  children: PropTypes.string.isRequired,
};

export default Segment;
