/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Link } from 'react-router-dom';

// import Segment from '../../Segment';
// Segment doens't run well inside of MouseLayer

const styles = {
  backgroundLayer: {
    position: 'relative',
    zIndex: 2,
    maxWidth: 600,
    padding: '10px 20px',
    margin: '4vh 2vw 0',
  },
};

export default () => (
  <div css={styles.backgroundLayer}>
    <h1>
      Websocket Experiment
    </h1>
    <h2>A websocket experiment</h2>
    <p>
      See the cursor of any other users connected to this page right now.
      Try it out by opening this page in multiple browsers, or sharing with a friend.
    </p>
    <p>
      <Link to="/">&lt; back</Link>
    </p>
  </div>
);
