import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { POSSIBLE_LETTERS } from '../../constants';

const possibleLetters = POSSIBLE_LETTERS.split('');

export default class Letter extends Component {
  static propTypes = {
    finalLetter: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    startOnFinal: PropTypes.bool,
  }

  static defaultProps = {
    startOnFinal: false,
  }

  constructor(props) {
    super(props);
    const { startOnFinal, finalLetter } = this.props;
    const randomIndex = parseInt(Math.round(Math.random() * possibleLetters.length), 10);

    this.state = {
      currentLetter: startOnFinal ? finalLetter : possibleLetters[randomIndex],
    };

    this.magicIndex = parseInt(Math.round(Math.random() * possibleLetters.length), 10);
  }

  // Starts a Frame Ticker
  componentDidMount() {
    const { startOnFinal } = this.props;
    if (!startOnFinal) {
      this.startTicker();
    }
  }

  /*
   * Custom Methods
   */
  onHover = () => {
    const randomIndex = parseInt(Math.round(Math.random() * possibleLetters.length), 10);
    this.setState({
      currentLetter: possibleLetters[randomIndex],
    });
    window.requestAnimationFrame(this.mutateCharacter);
  }

  mutateCharacter = () => {
    const { finalLetter } = this.props;
    const randomIndex = parseInt(Math.round(Math.random() * possibleLetters.length), 10);
    const randomLetter = possibleLetters[randomIndex];
    if (randomIndex === this.magicIndex) {
      return this.setState({
        currentLetter: finalLetter,
      });
    }
    window.requestAnimationFrame(this.mutateCharacter);
    if (randomLetter) {
      return this.setState({
        currentLetter: randomLetter,
      });
    }
    return null;
  }

  // Starts a Frame Ticker
  startTicker() {
    window.requestAnimationFrame(this.mutateCharacter);
  }

  render() {
    const { currentLetter } = this.state;
    // eslint-disable-next-line
    return <span onMouseOver={this.onHover}>{currentLetter}</span>;
  }
}
