/** @jsx jsx */
import { jsx } from '@emotion/core';
// import { Link } from 'react-router-dom';
import Segment from '../Segment';


const styles = {
  backgroundLayer: {
    position: 'relative',
    zIndex: 2,
    maxWidth: 600,
    padding: '10px 20px',
    margin: '4vh 2vw 0',
  },
  spacer: {
    marginBottom: '2rem',
  },
};

export default () => (
  <div className="homeLayer" css={styles.backgroundLayer}>
    <div css={styles.spacer}>
      <h1><Segment>Josh Guffey</Segment></h1>
      <p>
        <Segment startOnFinal>
          UI Enginner living in San Francisco, keeping one of the
        </Segment>
        &nbsp;
        <a href="https://facebook.com">
        most visited
        </a>
        &nbsp;
        <Segment startOnFinal>
          website in the world running smoothly.
        </Segment>
      </p>
      <p>
        <Segment startOnFinal>
          Projects that I’ve worked on include
        </Segment>
        &nbsp;
        <a href="https://myriad.com/">Myriad&#39;s provider portal</a>
        {', ' /* eslint-disable-line */ }
        <a href="https://yeti.co/work/westfield-kiosk/">an interactive kiosk</a>
        <Segment startOnFinal>
          {' for Westfield, and ' /* eslint-disable-line */ }
        </Segment>
        <a href="https://automile.com/">Automile</a>
        <Segment startOnFinal>
          {' a fleet tracking application.' /* eslint-disable-line */ }
        </Segment>
      </p>
    </div>
    {/*
      <div className="experiments" css={styles.spacer}>
        <h2>Experiments</h2>
        <ul>
          <Link to="/experiments/websockets">websockets</Link>
        </ul>
      </div>
    */}
    <div className="elsewhere" css={styles.spacer}>
      <h2>Elsewhere</h2>
      <ul>
        <li><a href="https://github.com/jguffey">github</a></li>
        <li><a href="https://stackoverflow.com/users/344405">stackoverflow</a></li>
        <li><a href="https://medium.com/@joshguffey">medium</a></li>
        <li><a href="https://twitter.com/jguffey">twitter</a></li>
      </ul>
    </div>
    <div style={{ marginTop: '12px' }}>
      <p>San Francisco, CA</p>
    </div>
  </div>
);
