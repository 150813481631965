/** @jsx jsx */
import { Component } from 'react';
import io from 'socket.io-client';
import { jsx } from '@emotion/core'; // eslint-disable-line
import _ from 'lodash';
import PropTypes from 'prop-types';

import { Mouse } from '../Mouse/Mouse';

import {
  MOUSE_MOVE_EVENT,
  POSITION_INCOMING,
  SOCKET_CONNECT,
  SOCKET_DISCONNECT,
  SOCKET_POLL,
} from '../../constants';

const styles = {
  backgroundLayer: {
    position: 'absolute',
    top: 0,
    left: 0,
    minWidth: '100vw',
    minHeight: '100vh',
  },
};

class MouseLayer extends Component {
  state = {
    otherPositions: {},
  }

  static propTypes = {
    children: PropTypes.node,
  }

  static defaultProps = {
    children: null,
  }

  componentDidMount() {
    const socket = io.connect(SOCKET_CONNECT);
    this.socket = socket;

    // Socket Events, subscribing
    socket.on(POSITION_INCOMING, this.onNewPosition);

    // Mouse Events, sending
    window.addEventListener('unload', () => {
      this.socket.emit(SOCKET_DISCONNECT);
    });
    this.onMouseMoveDebounced = _.throttle(this.onMouseMove, 10);

    this.layer.addEventListener(
      MOUSE_MOVE_EVENT,
      this.onMouseMoveDebounced,
      { passive: true },
    );
  }

  componentWillUnmount() {
    this.socket.emit(SOCKET_DISCONNECT);
    this.layer.removeEventListener(MOUSE_MOVE_EVENT, this.onMouseMoveDebounced);
  }

  onClicked = () => {}

  onMouseMove = (e) => {
    const position = { x: e.clientX, y: e.clientY };
    this.updateServer(position);
  }

  /* eslint-disable class-methods-use-this */
  onNewPosition = (data) => {
    const { otherPositions } = this.state;

    const username = Object.keys(data)[0];
    this.setState({
      otherPositions: {
        ...otherPositions,
        [username]: {
          x: data[username].x,
          y: data[username].y,
        },
      },
    });
  }
  /* eslint-enable class-methods-use-this */

  updateServer = (position) => {
    this.socket.emit(SOCKET_POLL, position);
  }

  render() {
    const { children } = this.props;
    const { otherPositions } = this.state;

    return (
      <div
        css={styles.backgroundLayer}
        className="mouseLayer"
        ref={(ref) => { this.layer = ref; }}
      >
        { children }
        {Object.keys(otherPositions).map((username) => (
          <Mouse position={otherPositions[username]} />
        ))}
      </div>
    );
  }
}

export default MouseLayer;
